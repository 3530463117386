
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import GlobalMultipleTable from "@/components/widgets/tables/GlobalMultipleTable.vue";

export default defineComponent({
  name: "result-tables",
  components: { GlobalMultipleTable },
  setup: function () {
    const router = useRouter();
    const route = useRoute();
    let data = ref();
    let userID = ref();
    let page = ref();
    let surveyID = ref();
    let surveyPercent = ref(0);
    let surveyValue = 0;
    let surveyMax = 0;
    const tableHeader = [
      {
        name: "Section",
        key: "id",
        sortable: false,
      },
      {
        name: "Question",
        key: "question",
        sortable: false,
      },
      {
        name: "Value",
        key: "value",
        sortable: false,
      },
      {
        name: "Text",
        key: "text",
        sortable: false,
      },
      {
        name: "Comment",
        key: "comment",
        sortable: false,
      },
      {
        name: "Date Time",
        key: "date",
        sortable: false,
      },
      {
        name: "status",
        key: "status",
        sortable: false,
      },
    ];
    onMounted(() => {
      userID.value = Number(route.params.user_id)
        ? route.params.user_id
        : router.push({ name: "users" });
      page.value =
        route.params.page === "users"
          ? `user/${userID.value}/details`
          : route.params.page;
      surveyID.value = Number(route.params.id)
        ? route.params.id
        : router.push({ name: "users" });
      getResults();
    });
    const getColor = (score) => {
      if (score < 40) {
        return "bg-danger";
      } else if (score >= 40 && score < 65) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    };
    const getScore = (value, max) => {
      surveyValue += value ? Number(value) : 0;
      surveyMax += max ? Number(max) : 0;
      surveyPercent.value = Math.round((surveyValue / surveyMax) * 100);
      return null;
    };

    const getPercent = (value, max) => {
      return value && max ? Math.round((value / max) * 100) : 0;
    };

    async function getResults() {
      apiService
        .get(`/user/${userID.value}/survey/${surveyID.value}/result`)
        .then(
          await function (resp) {
            if (resp.data.data) {
              data.value = resp.data?.data;
            }
          }
        )
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    return {
      data,
      tableHeader,
      getPercent,
      getColor,
      getScore,
      surveyPercent,
      page,
    };
  },
});
