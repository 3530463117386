
import { defineComponent, onMounted } from "vue";
import ResultTables from "@/components/widgets/tables/ResultTables.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "users-details-result",
  components: {
    ResultTables,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Result", ["Users", "Details"]);
    });
  },
});
