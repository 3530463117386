
import { defineComponent, onMounted } from "vue";
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JQuery from "jquery";

export default defineComponent({
  name: "global_multiple",

  setup: function () {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let table;
    onMounted(() => {
      newDataTable();
    });

    function newDataTable() {
      // eslint-disable-next-line
      const $dt: JQuery & { dataTable?: any } = JQuery(".globalMultiTable");
      // eslint-disable-next-line
      table = $dt.dataTable({
        destroy: true,
        pagingType: "full_numbers",
        pageLength: 5,
        searching: false,
        paging: false,
        processing: true,
        info: false,
        ordering: false,
        dom: "Bfrtip",
        buttons: [
          { extend: "copy", className: "btn btn-primary btn-sm" },
          { extend: "csv", className: "btn btn-primary btn-sm" },
          { extend: "excel", className: "btn btn-primary btn-sm" },
          { extend: "pdf", className: "btn btn-primary btn-sm" },
          { extend: "print", className: "btn btn-primary btn-sm" },
        ],
      });
    }
  },
});
